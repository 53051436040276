// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import titleCase from "ap-style-title-case";
import Parallax from "parallax-js";

import "@fortawesome/fontawesome-free/css/all.css"

// Import bootstrap
import "@popperjs/core";
import "bootstrap";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap";

// Growth hacks
import exitIntent from "exit-intent-mobile-bugfix";
import ahoy from "ahoy.js";

// Make the package import global
window.titleCase = titleCase;
window.modal = Modal;
window.bootstrap = bootstrap;
window.exitIntent = exitIntent;
window.ahoy = ahoy;

// Parallax.js not currently in use, but really great for adding depth later so keeping around
//import Parallax from 'parallax-js'
//window.Parallax = Parallax

Rails.start();
// Turbolinks.start();
ActiveStorage.start();
